import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Brands } from '../../../../../models/brands.model';

const API_BRANDS_URL = environment.serverUrl + 'brands';

@Injectable()

export class BrandsService {
  constructor(
    private http: HttpClient
  ) { }

  paginate(pageSize = '15', pageNumber = '1', state?): Observable<Brands[]> {
    return this.http.get<Brands[]>(`${API_BRANDS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...state
      }
    });
  }

  sort(pageSize = '15', pageNumber = '1', state): Observable<Brands[]> {
    const sort = state.sort ? {sortBy: state.sort.key, desc: state.sort.value} : {};
    return this.http.get<Brands[]>(`${API_BRANDS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...sort
      }
    });
  }
  
  find(id: number): Observable<Brands> {
    return this.http.get<Brands>(`${API_BRANDS_URL + '/' + id}`);
  }

  add(data: Brands): Observable<any> {
    return this.http.post<Brands>(`${API_BRANDS_URL}`, data);
  }

  update(data: Brands): Observable<any> {
    const id = data.id;
    delete data.id;
    return this.http.patch<Brands>(`${API_BRANDS_URL + '/' + id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<Brands>(`${API_BRANDS_URL + '/' + id}`);
  }
}
