import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

const API_USERS_URL = environment.serverUrl + 'users';

@Injectable()

export class UsersService {
  constructor(
    private http: HttpClient
  ) { }

  list(state = {}): Observable<any> {
    return this.http.get(`${API_USERS_URL}`, {
      params: {
        perPage: '1000',
        ...state
      }
    });
  }

  paginate(pageSize = '15', pageNumber = '1', state?): Observable<any> {
    return this.http.get(`${API_USERS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...state
      }
    });
  }

  sort(pageSize = '15', pageNumber = '1', state): Observable<any> {
    const sort = state.sort ? { sortBy: state.sort.key, desc: state.sort.value } : {};
    return this.http.get(`${API_USERS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...sort
      }
    });
  }

  filter(pageSize = '15', pageNumber = '1', param): Observable<any> {
    return this.http.get(`${API_USERS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...param
      }
    });
  }

  assign(id, data): Observable<any> {
    return this.http.patch(`${API_USERS_URL + '/assign/roles/' + id}`, data);
  }

  find(id: number): Observable<any> {
    return this.http.get(`${API_USERS_URL + '/' + id}`);
  }

  add(data): Observable<any> {
    return this.http.post(`${API_USERS_URL}`, data);
  }

  update(data): Observable<any> {
    const id = data.id;
    delete data.id;
    return this.http.patch(`${API_USERS_URL + '/' + id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${API_USERS_URL + '/' + id}`);
  }

  block(data): Observable<any> {
    return this.http.patch(`${API_USERS_URL + '/block/' + data.id}`, data);
  }

  unblock(data): Observable<any> {
    return this.http.patch(`${API_USERS_URL + '/unblock/' + data.id}`, data);
  }
}
