import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';

export const API_ATTORNEY_URL = environment.serverUrl + 'attorneyPersons';

@Injectable()

export class AttorneyService {

  constructor(
    private http: HttpClient
  ) { }
  
  list(state = {}): Observable<any> {
    return this.http.get<any>(`${API_ATTORNEY_URL}`, {
      params: {
        perPage: '1000',
        ...state
      }
    });
  }

  paginate(pageSize = '15', pageNumber = '1'): Observable<any> {
    return this.http.get<any>(`${API_ATTORNEY_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
      }
    });
  }

  sort(pageSize = '15', pageNumber = '1', state): Observable<any> {
    const sort = state.sort ? { sortBy: state.sort.key, desc: state.sort.value } : {};
    return this.http.get(`${API_ATTORNEY_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...sort
      }
    });
  }

  find(id: number): Observable<any> {
    return this.http.get(`${API_ATTORNEY_URL + '/' + id}`);
  }

  add(data): Observable<any> {
    return this.http.post(`${API_ATTORNEY_URL}`, data);
  }

  update(data): Observable<any> {
    const id = data.id;
    delete data.id;
    return this.http.patch(`${API_ATTORNEY_URL + '/' + id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${API_ATTORNEY_URL + '/' + id}`);
  }
}
