import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

export const API_NEWS_URL = environment.serverUrl + 'news';

@Injectable()

export class NewsService {
  constructor(
    private http: HttpClient
  ) { }

  paginate(pageSize = '15', pageNumber = '1'): Observable<any> {
    return this.http.get<any>(`${API_NEWS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
      }
    });
  }

  sort(pageSize = '15', pageNumber = '1', state): Observable<any> {
    const sort = state.sort ? {sortBy: state.sort.key, desc: state.sort.value} : {};
    return this.http.get(`${API_NEWS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...sort
      }
    });
  }
  
  find(id: number): Observable<any> {
    return this.http.get(`${API_NEWS_URL + '/' + id}`);
  }

  add(data): Observable<any> {
    return this.http.post(`${API_NEWS_URL}`, data);
  }

  update(data): Observable<any> {
    const id = data.id;
    delete data.id;
    return this.http.patch(`${API_NEWS_URL + '/' + id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${API_NEWS_URL + '/' + id}`);
  }
}
