import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';

export const API_GROUPS_URL = environment.serverUrl + 'attributeGroups';

@Injectable()

export class GroupsService {
  constructor(
    private http: HttpClient
  ) { }

  paginate(pageSize = '15', pageNumber = '1'): Observable<any> {
    return this.http.get(`${API_GROUPS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
      }
    });
  }

  sort(pageSize = '15', pageNumber = '1', state): Observable<any> {
    const sort = state.sort ? {sortBy: state.sort.key, desc: state.sort.value} : {};
    return this.http.get<any>(`${API_GROUPS_URL}`, {
      params: {
        perPage: pageSize,
        page: pageNumber,
        ...sort
      }
    });
  }
  
  find(id: number): Observable<any> {
    return this.http.get(`${API_GROUPS_URL + '/' + id}`);
  }

  add(data): Observable<any> {
    return this.http.post<any>(`${API_GROUPS_URL}`, data);
  }

  update(data): Observable<any> {
    return this.http.patch<any>(`${API_GROUPS_URL + '/' + data.id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${API_GROUPS_URL + '/' + id}`);
  }
}
