var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { InlineSVGComponent } from './inline-svg.component';
import { InlineSVGConfig } from './inline-svg.config';
import { InlineSVGDirective } from './inline-svg.directive';
var InlineSVGModule = (function () {
    function InlineSVGModule() {
    }
    InlineSVGModule_1 = InlineSVGModule;
    InlineSVGModule.forRoot = function (config) {
        return {
            ngModule: InlineSVGModule_1,
            providers: [
                { provide: InlineSVGConfig, useValue: config }
            ]
        };
    };
    var InlineSVGModule_1;
    InlineSVGModule = InlineSVGModule_1 = __decorate([
        NgModule({
            declarations: [InlineSVGDirective, InlineSVGComponent],
            exports: [InlineSVGDirective],
            entryComponents: [InlineSVGComponent]
        })
    ], InlineSVGModule);
    return InlineSVGModule;
}());
export { InlineSVGModule };
